.article{

    position: relative;

    margin: 0 0 20px 0;
    transition: all .2s ease-in-out;


    &--active{
        //margin: 0 0 100px 0;
    }

    &--media-carousel{
        .article__media > div{
            
        }
    }

    &:last-of-type{
        border: none;
    }
    
    h2{
        margin: 0px 0 0px 0;
        cursor: pointer;
    }

    h4{
        margin: 0;
    }
}