.content{

    // margin: 0 auto;
    padding: 20px 20px 20px $sidebar_w_full;
    // min-height: calc( 100vh - 40px);
    // background: #eee;
    overflow-x: visible;
    overflow-y: visible;

    // padding-left: calc( $sidebar_w_full + $content_left_pad );
    width: calc( 100vw - $sidebar_w_full);
    max-width: 900px;
    
    @include breakpoint( $breakpoint_l ) {
        padding-left: calc( $sidebar_w_l + $content_left_pad );
        max-width: none;
    }

    @include breakpoint( $breakpoint_m ) {
        padding: 20px 20px;
        margin-top: 80px;
        max-width: none;
        width: calc( 100vw - 40px );
    }


    h1{
        margin: 0;
        padding: 5px 0;

        &:empty{
            display: none;
        }
    }

    h2{
        margin: 0;
        padding: 5px 0;

        &:empty{
            display: none;
        }
    }

    h3{
        margin: 0;
        padding: 5px 0;

        &:empty{
            display: none;
        }
    }

    p{
        margin: 0;
        padding: 5px 0;

        &:empty{
            display: none;
        }
    }
    
    pre{
        word-break: break-all;
        white-space: normal;
    }

    img{
        max-width: 100%;
    }

    a{
        margin: 0;
        padding: 5px 5px;
        color: #222;
        text-decoration: none;
        transition: all .25s ease-in-out;

        &:hover,
        &.active
        {
            color: $color_accent;
        }
    }

    button{
        cursor: pointer;
    }

}