html,body{
    margin: 0;
    padding: 0;
}

body {

    width: 100vw;

    margin: 0;
    padding: 0;

    overflow-x: hidden;

    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: #fff;
}



.main{
    &[data-menu="true"]{
        .content *{
            pointer-events: none;
        }
    }
}

.wrapper{

    display: flex;
    
    color: #222;

    @include breakpoint( $breakpoint_m ) {
        display: block;
    }
}