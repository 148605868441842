@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@100;300;400;700&display=swap');

.main,
button{
    font-family: 'Inter', sans-serif;
}

h1{
    font-size: 80px;
    line-height: 90px;
    letter-spacing: -5px;
    margin: 0;
    font-weight: 700;
    font-family: 'Hind', serif;
    text-transform: uppercase;
    overflow: hidden;

    @include breakpoint( $breakpoint_m ) {
        font-size: 60px;
        line-height: 60px;
        letter-spacing: -4px;
    }
}
h2{
    font-size: 60px;
    line-height: 65px;
    letter-spacing: -2.5px;
    margin: 0;
    
    @include breakpoint( $breakpoint_m ) {
        font-size: 45px;
        line-height: 45px;
        letter-spacing: -1.7px;
    }
}
h3{
    font-size: 30px;
    line-height: 35px;
    margin: 0;

    @include breakpoint( $breakpoint_m ) {
        font-size: 20px;
        line-height: 30px;
    }
}
h4{
    font-size: 24px;
    line-height: 34px;
    margin: 0;
}
h5{
    font-size: 1.44rem;
    margin: 0;
}
h6{
    font-size: 1.2rem;
    margin: 0;
}
p{
    font-size: 16px;
    line-height: 24px;
    margin: 0;

    @include breakpoint( $breakpoint_m ) {
        font-size: 12px;
        line-height: 19px;
    }
}
small{
    display: inline-block;
    font-size: 11px;
    line-height: 15px;
}

a{
    text-decoration: none;
}