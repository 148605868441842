.section{

    margin: 0 0 20px 0;
    padding: 10px 10px 5px 10px;
    // border: 1px solid #666;

    &.fx{
        .article:first-of-type{
            opacity: 0;
            transform: translateX(20px);
        }
    }

}