$sidebar_w_full: 350px;
$sidebar_w_l: 200px;

$content_left_pad: 100px;

$color_accent: #f00;
$color_active_route: #f00;

/* breakpoints */
$breakpoint_s: 500px;
$breakpoint_m: 767px;
$breakpoint_l: 992px;
$breakpoint_xl: 1450px;