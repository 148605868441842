.details{

    position: fixed;
    z-index: 599;
    bottom: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background: #fff;
    overflow-y: auto;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    &:empty{
        display: none;
    }

    .wrapper{
        padding: 80px 50px 80px 50px;
        display: block;

        // max-width: 700px;
        min-height: 100vh;

        @include breakpoint( $breakpoint_m ) {
            padding: 40px 20px 40px 20px;
        }

        .hero{
            position: absolute;
            left: 0;
            top: 0;
            width: 100vw;
            height: 33vh;
            overflow: hidden;
            background: #eee;

            @include breakpoint( $breakpoint_m ) {
                height: 50vh;
            }

            .media{
                height: 100%;
            }
            img{
                width: 100%;
                object-fit: cover;
            }
        }


        .double{
            display: flex;
            flex-direction: row;

            @include breakpoint( $breakpoint_l ) {
                flex-direction: column;
            }

            & > div:nth-of-type(1){
                width: 40%;
                @include breakpoint( $breakpoint_l ) {
                    width: 100%;
                }
            }
            & > div:nth-of-type(2){
                margin: 0 0 0 10%;
                width: 50%;

                @include breakpoint( $breakpoint_l ) {
                    margin: 0;
                    width: 100%;
                }
            }
        }

        .article__media{
            margin: 30px 0 0 0;
        }


        .ctas{
            margin: 50px 0;

            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @include breakpoint( $breakpoint_m ) {
                display: block;
                margin: 50px 0 0 0;
                flex-direction: column;
                justify-content: stretch;
            }

            button,a{
                display: block;
                margin: 5px 20px 0 0;
                padding: 8px 12px;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: -0.25px;
                border: none;
                background: #fff;
                color: #222;
                white-space: nowrap;
                border: 4px solid #222;

                @include breakpoint( $breakpoint_m ) {
                    margin: 10px 0;
                }
            }



            &__back{
                text-align: center;

                &:before,
                &:after{
                    content: ''
                }
            }

            &__prev,
            &__next{
                display: block;
                min-width: 150px;
                border-color: $color_accent !important;
                background: $color_accent !important;
                color: #fff !important;
            }

            &__prev-up,
            &__next-up{
                display: block;
                min-width: 150px;
                border-color: #333 !important;
                background: #333 !important;
                color: #fff !important;
            }

            &__prev{
                text-align: left;

                &:before{
                    margin: 0 12px 0 5px;
                    content: '';
                    border: solid #fff;
                    display: inline-block;
                    border-width: 0 2px 2px 0;
                    padding: 2px;

                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
            }
            &__next{
                text-align: right;
                &:after{
                    margin: 0 5px 0 12px;
                    content: '';
                    border: solid #fff;
                    display: inline-block;
                    border-width: 0 2px 2px 0;
                    padding: 2px;

                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }
            }
            &__prev-up{
                text-align: left;

                &:before{
                    margin: 0 12px 0 5px;
                    content: '';
                    border: solid #fff;
                    display: inline-block;
                    border-width: 0 2px 2px 0;
                    padding: 2px;

                    transform: rotate(-135deg);
                    -webkit-transform: rotate(-135deg);
                }
            }
            &__next-up{
                text-align: right;
                &:after{
                    margin: 0 5px 0 12px;
                    content: '';
                    border: solid #fff;
                    display: inline-block;
                    border-width: 0 2px 2px 0;
                    padding: 2px;

                    transform: rotate(-135deg);
                    -webkit-transform: rotate(-135deg);
                }
            }
        }
    }

    

}