.loader{

    position: fixed;
    z-index: 9999;
    top: 0px;

    height: 5px;
    background: $color_accent;

    animation: 2s infinite scaling;

    &--absolute{
        top: auto;
        bottom: 0;
        position: absolute;
        background: #ccc;
        z-index: 200;
    }

}

@keyframes scaling {
    0% {
        left: 0;
        right: auto;
        width: 0;
    }
    49% {
        left: 0;
        right: auto;
        width: 100%;
    }
    50% {
        left: auto;
        right: 0;
        width: 100%;
    }
    99% {
        left: auto;
        right: 0;
        width: 0%;
    }
    100% {
        left: 0;
        right: auto;
        width: 0%;
    }
  }