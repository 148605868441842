.sidebar{

    position: fixed;
    z-index: 500;
    top: 0px;
    left: 0;
    
    // width: $sidebar_w_full;
    height: calc( 100vh - 40px );
    padding: 20px 5px;

    display: flex; 
    flex-direction: column;

    overflow-x: auto;
    overflow-y: auto;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    @include breakpoint( $breakpoint_m ) {
        width: calc(80vw - 10px);
        background: #fff;
        box-shadow: 10px 0 20px rgba(0,0,0,.1);
    }

    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @media (hover) and (prefers-reduced-motion: no-preference) {
            & > a,
            & > span {
              transition: opacity .2s ease-in-out !important;
            }
            
            &:hover > span:not(:hover),
            &:hover > a:not(:hover) {
              opacity: .25 !important;
            }
        }
    }

    a{
        display: block;
        margin: 5px 10px;
        padding: 3px 16px;
    
        background: none;
        color: #222;

        font-size: 14px;
        font-weight: 700;
    
        white-space: wrap;
        cursor: pointer;
        border: none;
        text-align: left;
        text-decoration: none;

        @include breakpoint( $breakpoint_m ) {
            font-size: 18px;
        }

        &:hover,
        &.active{
            color: $color_active_route;
        }

        
    }

    [data-sub-level]{

        display: flex;
        flex-direction: column;

        [data-sub-level]{
            display: none;
            &:has(a.active){
                display: flex;
            }
            @include breakpoint( $breakpoint_m ) {
                display: flex;
            }
        }
        &:has(.active) > a{
            color: $color_active_route;
        }
        &:hover{
            [data-sub-level]{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }   
        }

        &[data-sub-level="1"]{
            // border-top: 1px dashed transparent;
            // border-bottom: 1px dashed transparent;
            &:has(.active){
                // border-top-color: rgba(255,255,255,.25);
                // border-bottom-color: rgba(255,255,255,.25);
            }
        }
        &[data-sub-level="2"]{
            padding: 0 0 0 10px;
            a{
                margin: 5px 10px;
                font-weight: 700;
            }
        }
        &[data-sub-level="3"]{
            // display: none !important;
            padding: 0 0 0 20px;
            a{
                margin: 5px 10px;
                font-weight: 700;
            }
        }
        &[data-sub-level="4"]{
            // display: none !important;
            padding: 0 0 0 30px;
            a{
                margin: 5px 10px;
                font-weight: 700;
            }
        }
        &[data-sub-level="5"]{
            // display: none !important;
            padding: 0 0 0 60px;
            a{
                // font-size: 14px;
                font-weight: 700;
            }
        }
        
    }

    .highlight{
        background: $color_accent;
        color: #222;
    }

    svg{
        width: 32px;
        height: 32px;
        fill: $color_accent;
        pointer-events: none;
    }

    small{
        font-size: 11px;
    }


}

.sidebar-toggle{

    position: fixed;
    display: none;
    z-index: 600;

    @include breakpoint( $breakpoint_m ) {
        display: flex;
    }

    top: 30px;
    left: 20px;

    border: none;
    background: transparent;

    cursor: pointer;

    svg{
        width: 32px;
        height: 32px;
        fill: $color_accent;
        pointer-events: none;
    }
}