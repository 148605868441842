
/*** pages ***/

.page{

    /*** pages */

    &--home{

        // display: flex;
        // align-items: center;
        color: $color_accent;

        // height: calc( 100vh - 40px );

        @include breakpoint( $breakpoint_m ) {
            // height: calc( 100vh - 120px);
        }

        .slides{
            
            width: 100vw;
            height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;

            scroll-snap-type: y mandatory;
            scroll-padding: 50px;

            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar { 
                display: none;  /* Safari and Chrome */
            }
            
            
        }

        .slide{
            position: relative;
            width: 60vw;
            height: 60vh;
            margin: 20vh 20vw;
            padding: 40px 0 0 0;
            overflow: visible;

            scroll-snap-align: center;

            @include breakpoint( $breakpoint_m ) {
                width: 60vw;
                height: 80vh;
                margin: 10vh 20vw;
            }

            &--hero{
                h5{
                    max-width: 400px;
                }
            }
            &--content{
                //
            }


            h1{
                // font-size: 200px;
                //line-height: 200px;

                @include breakpoint( $breakpoint_m ) {
                    font-size: 60px;
                    line-height: 60px;
                }
            }

            a:not(.breadcrumbs__item){
                display: inline-block;
                margin: 10px;
                padding: 10px 20px;
                font-weight: 700;
            }

            .article__media{
                max-width: 300px;
            }

            .hero{
                z-index: -1;
                object-fit: cover;
                object-position: center center;
                height: 80vh;
                width: 80vw;
                bottom: 0vh;
                left: 10vw;
                padding: 0;
                position: absolute;
                // opacity: .8;
                // transform: scale(1);

                @include breakpoint( $breakpoint_m ) {
                    top: 25vh;
                    height: 50vh;
                }
            }

        }

        .pagination{
            position: fixed;
            bottom: 10px;
            right: 10px;

            display: flex;
            flex-direction: row;

            @include breakpoint( $breakpoint_m ) {
                flex-direction: column;
                justify-content: flex-start;
                right: auto;
                left: 30px;
            }

            &-item{
                margin: 0 10px;
                padding: 5px 1px;                

                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: .2px;

                border: none;
                background: transparent;
                cursor: pointer;

                color: #222;

                transition: all .25s ease-in-out;

                border-bottom: 3px solid transparent;

                &:hover,
                &--active{
                    color: $color_accent;
                    opacity: 1;
                    border-bottom-color: #222;
                }

                &--inactive{
                    opacity: 1;
                    // text-decoration: line-through;
                }

                @include breakpoint( $breakpoint_m ) {
                    margin: 2px 5px;
                    padding: 0 1px;
                    text-align: left;
                }
            }
        }

    }


    &--contact{

        .form{
            display: flex;
            flex-direction: column;

            width: 400px;
        }

        fieldset{
            margin: 0;
            padding: 5px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            border: none;
        }

        label{
            display: inline-block;
            vertical-align: text-top;
            min-width: 100px;
            text-transform: uppercase;
        }

        input,
        textarea{
            width: 100%;
            margin: 0;
            padding: 10px;
            font-family: 'Jost', sans-serif;
        }

        span{
            text-transform: uppercase;
            cursor: pointer;
        }

        button{
            padding: 10px 20px;
            background: $color_accent;
            color: #222;
            border: none;
        }
    }


    &--category{

        .category{
            &__head{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                
                border-bottom: 1px dashed #555;

                h1{
                    display: block;
                }
            }

            &__items{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            &__item{
                position: relative;
                margin: 30px 0px 30px 0;
                padding: 0px;
                width: calc( 90% - 80px );
                // overflow: hidden;
                background-size: cover;

                @include breakpoint( $breakpoint_m ) {
                    width: calc( 100% - 40px );
                }

                h2{
                    letter-spacing: -1.5px;
                    // text-transform: uppercase;

                    @include breakpoint( $breakpoint_m ) {
                        letter-spacing: -1px;
                    }
                }
                h3{
                    
                }

                & > a{
                    color: #222;
                    transition: all .2s ease-in-out;

                    &:hover{
                        color: $color_accent;
                    }
                    & *{
                        pointer-events: none;
                    }
                }
            }
        }
    }


    &--presscuts{

        .section{
            display: flex;
            flex-wrap: wrap;

            .article{
                width: calc( 50% - 48px);
                margin: 20px;
                padding: 0;
                background: #fff;
                border: 4px solid #222;

                @include breakpoint( $breakpoint_l ) {
                    margin: 10px 0;
                    width: 100%;
                }

                h2{
                    padding: 5px 15px;
                    font-size: 30px;
                    line-height: 40px;
                    letter-spacing: -1.4px;
                    background: #222;
                    color: #fff;

                    @include breakpoint( $breakpoint_l ) {
                        font-size: 18px;
                        line-height: 25px;
                    }
                }

                p{
                    padding: 10px;
                    font-style: italic;
                }
            }

        }
    }



    /*** elements */
    
    &__links{
        display: none;
    }
}





/*** wrappers ***/

[data-slug="home"]{

    .content{
        margin: 0;
        padding: 0;
        width: 100vw;
        // max-width: none;
        // height: calc( 100vh - 40px );
        word-break: break-word;
        /*
        display: flex;
        justify-content: flex-start;
        align-items: center;
        */

        @include breakpoint( $breakpoint_m ) {
            // width: calc( 100% - 0px );
        }
    }
}

[data-slug="contact"]{

    .btn{
        cursor: pointer;

        &--valid{
            background: #00f;
            color: #222,
            
        }
        &--invalid{
            background: #ccc;
			text-decoration: line-through;
        }
    }

}

[data-type="detail"]{
    // height: 100vh;
    // overflow: hidden;
}