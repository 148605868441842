.breadcrumbs{

    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    &:empty{
        display: none;
    }
    
    
    &--home{
        margin: 120px 0 30px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .breadcrumbs__item{
            margin: 10px 0 0 0px;
            padding: 35px 50px 8px 10px;
            // text-transform: uppercase;
            font-size: 15px;
            line-height: 15px;
            font-weight: 900;
            letter-spacing: -.35px;
            background: #fff;
            color: #222 !important;
            border: 4px solid #222;

            @include breakpoint( $breakpoint_m ) {
                margin: 10px 0 0 0;
            }

            &:after{
                display: block !important;
                right: 12px;
                bottom: 12px;
            }

            &:hover,
            &.active{
                background: $color_accent;
                color: #fff !important;

                &:after{
                    border-color: #fff !important;
                }
            }
        }
    }

    &--category-head{
        margin: 0 0 30px 0;


        .breadcrumbs__item{
            margin: 0 0 5px 5px;
            padding: 35px 50px 8px 10px;
            font-size: 15px;
            line-height: 15px;
            font-weight: 900;
            letter-spacing: -.35px;
            background: #fff;
            color: #222 !important;
            border: 4px solid #222;

            &:after{
                // display: none;
                right: 12px;
                bottom: 12px;
            }

            &:hover,
            &.active{
                background: $color_accent;
                color: #fff !important;

                &:after{
                    border-color: #fff !important;
                }
            }
        }
    }

    &--category-item{
        margin: 0 0 0 0px;
        padding: 0;

        .breadcrumbs__item{
            padding: 4px 0 4px 2px;
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
            font-weight: 700;

            &:after{
                position: relative;
                margin: 2px 3px;
                border-width: 0 1px 1px 0;
                padding: 1px;
            }
        }
    }

    &--details{
        margin: 0;
        padding: 0;

        .breadcrumbs__item{
            padding: 4px 0 4px 2px;
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
            font-weight: 700;

            &:after{
                position: relative;
                margin: 2px 3px;
                border-width: 0 1px 1px 0;
                padding: 1px;
            }
        }
    }




    &__item{
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;
        // background: #555;

        &:after{
            position: absolute;

            content: '';
            border: solid black;
            display: inline-block;
            border-width: 0 2px 2px 0;
            padding: 2px;

            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);

            /*
            right: -2px;
            margin: 0 0 0 5px;
            padding: 0 0 0 4px;
            /*
            content: '>';
            opacity: .95;
            // font-size: 16px;
            // line-height: 1px;
            // font-weight: 700;
            color: $color_accent !important;
            */
        }

        &:last-of-type{
            &:after{
                display: none;
            }
        }

        @include breakpoint( $breakpoint_m ) {

        }

    }

}